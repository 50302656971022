import React from "react";
import {CATEGORIES} from "../../helpers/constants";

const baseMultiSelectProps = [
  {key: 'textValue', type: 'string', label: 'Leading Header (Optional)'},
  {key: 'label', type: 'string', default: 'Choose a label', label: 'Question'},
  {key: 'possibleValues', type: 'possibleMultiSelect', default: [], label: 'Possible Answers'},
  {key: 'multiAnswer', type: 'bool', default: false, label: 'Allow Multiple Answers'},
  {key: 'confidential', type: 'bool', default: false, label: 'Confidential (Indicates potentially sensitive information)'},
  {key: 'conditional', type: 'conditional', default: {when: 'always', question: null, contains:''}, label: 'Conditional Show'}
];

export const MultiSelectProps = [
  {key: 'propertyName', type: 'string', default: 'Property Name', label: "Property Name", disabled: true},
  ...baseMultiSelectProps
]

export const MultiSelectNewProps = [
  {key: 'propertyName', type: 'string', default: 'Property Name', label: "Property Name"},
  ...baseMultiSelectProps,
  {key: 'category', type: 'select', default: 'Misc', selectValues: CATEGORIES, label: 'Category'}
];

const MultiSelect = () => <div />

export default MultiSelect;
