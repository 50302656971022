import React from 'react';

const Swoosh = ({background = 'primary.main'}) => {
  return (<div className={'swoosh'}><svg xmlns="http://www.w3.org/2000/svg"
                                         style={{
                                           position: 'absolute',
                                           left: '50%',
                                           transform: 'translateX(-50%)'
                                         }}
                                         width="1920"
                                         height="405.957"
                                         viewBox="0 0 1920 405.957">
    <path id="Header" d="M-82.441,1390.99s481.192,271.149,1041.873,99.574l878.127-283.4v405.957h-1920Z"
          transform="translate(82.441 -1207.16)" fill={background} />
  </svg></div>)
}

export default Swoosh;
