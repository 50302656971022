import React, {useContext, useEffect, useMemo, useState} from "react";
import {Box} from '@mui/material';
import {documentStore} from "../documentStore";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from '@mui/system';

const FormContainer = ({children}) => {
  const {docState, appDispatch} = useContext(documentStore);
  const [currentTheme, setCurrentTheme] = useState();

   useEffect(() => {
     if (currentTheme) {
       appDispatch({ type: 'update palette', payload: currentTheme });
     }
   }, [currentTheme, appDispatch]);

  const darkTheme = useMemo(() => {
    const currentTheme = createTheme({palette: docState.present.kiteTheme});
    //dispatch({ type: 'update palette', payload: currentTheme });
    setCurrentTheme(currentTheme);
    return currentTheme;
  }, [docState.present.kiteTheme]);

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{backgroundColor: 'background.default', width: '100%', maxWidth: '1920px', margin: '0 auto'}} onClick={() => {
        appDispatch({
          type: 'update selected',
          payload: {
            key: '',
            tab: 0,
            index: 1
          }
        });
      }}>
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default FormContainer;
