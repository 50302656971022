import React from "react";
import {CATEGORIES} from "../../helpers/constants";

export const TextFieldNewProps = [
  {key: 'propertyName', type: 'string', default: 'Property Name', label: "Property Name"},
  {key: 'label', type: 'string', default: 'Input Label', label: 'Question'},
  {key: 'category', type: 'select', default: 'Misc', selectValues: CATEGORIES, label: 'Category'},
  {key: 'confidential', type: 'bool', default: false, label: 'Confidential (Indicates potentially sensitive information)'},
  {key: 'conditional', type: 'conditional', default: {when: 'always', question: null, contains:''}, label: 'Conditional Show'},
]

const TextFieldNew = () => <div />

export default TextFieldNew;
