import React, {useContext} from 'react';
import ContactUs from "./ContactUs";
import PageCenter from "../Atoms/PageCenter";
import {documentStore} from "../documentStore";
import VenueContactDetails from "./VenueContactDetails";

const FormFooter = ({editable = false}) => {
  const {docState} = useContext(documentStore);

  return (<div>
    <PageCenter style={{zIndex:1}}>
      <ContactUs {...docState.present.contact[0]} editable={editable} />
    </PageCenter>
    <VenueContactDetails {...docState.present.footer[0]} editable={editable} />
  </div>)
}

export default FormFooter;
