import React, {useContext, useState, useEffect} from 'react';
import {documentStore} from "../documentStore";
import {getDocument, getQuestions, update} from "../KiteDocuments/api";
import {useParams} from "react-router-dom";
import useDebounce from "../hooks/useDebounce";
import TabPanel from "Molecules/TabPanel";
import EditComponents from "./EditComponents";
import PreviewEdit from "./PreviewEdit";
import Layout from "../Components/Layout/Layout";

/**
 * Container Component for the from edit and the actual form
 * @returns {JSX.Element}
 * @constructor
 */
const Editor = () => {
  let { id, tab } = useParams();
  const {docState, docDispatch, appDispatch} = useContext(documentStore);
  let selectedTab = parseInt(tab) || 0;
  const debouncedState = useDebounce(docState.present, 500);
  const [kiteEntry, setKiteEntry] = useState({});
  const [loadedDoc, setLoadedDoc] = useState(false);

  useEffect(() => {
    if (debouncedState.shape && debouncedState.shape.length && id && loadedDoc) {
      debouncedState.shape.forEach(shape => {
        if (shape.locked) {
          shape.locked = false;
        }
        if (shape.type === 'text') {
          shape.type = 'content';
        }
      });
      update(id, {document: debouncedState});
    }
  }, [debouncedState, id, loadedDoc]);

  // intercept the undo and redo keyboard shortcuts, with mac support
  const handleUndoRedo = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 'z' && !e.shiftKey) {
      e.preventDefault();
      docDispatch({ type: 'UNDO' });
    }
    // using shift + cmd + z for redo
    if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.key === 'z') {
      e.preventDefault();
      docDispatch({ type: 'REDO' });
    }
  }

  useEffect(() => {
    if (id) {
      getQuestions().then(res => {
        let questionsList = res.data;
        if (questionsList.length) {
          const sortByPropertyName = questionsList.sort((a, b) => (a.propertyName > b.propertyName) ? 1 : ((b.propertyName > a.propertyName) ? -1 : 0));
          const sortByCategory = sortByPropertyName.sort((a, b) => (a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0));
          appDispatch({ type: 'update questions', payload: sortByCategory });
        }
      });
    }
  }, [appDispatch, id]);

  useEffect(() => {
    if (id) {
      getDocument(id).then(res => {
        let doc = res.data[0];
        if (doc.document) {
          docDispatch({ type: 'RESET', payload: doc.document });
          appDispatch({ type: 'PUBLISH', payload: doc.lastPublished });
        }
        setKiteEntry(doc);
        setLoadedDoc(true);
      });
    }
  }, [id, docDispatch, appDispatch]);

  return (
  <div onKeyDown={handleUndoRedo} tabIndex={-1}>
    <Layout kiteEntry={kiteEntry} debouncedState={debouncedState} keypress >
      <TabPanel value={selectedTab} index={0}>
        <EditComponents loadedDoc={loadedDoc} id={id} kiteEntry={kiteEntry} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <PreviewEdit id={id} />
      </TabPanel>
    </Layout>
  </div>);
};

Editor.propTypes = {

};

export default Editor;
