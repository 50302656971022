import React from 'react';
import IntroParagraph from "../Atoms/FormContent/IntroParagraph";
import SectionHead from "../Atoms/FormContent/SectionHead";
import Paragraph from "../Atoms/FormContent/Paragraph";
import ShareLink from 'Atoms/FormContent/ShareLink';

const ContentElement = ({
  config
}) => {

  return (
    <React.Fragment>
    {!config.hidden &&
      <React.Fragment>
        {config.component === 'IntroParagraph' && (
          <IntroParagraph {...config} />
        )}

        {config.component === 'SectionHead' && (
          <SectionHead {...config} />
        )}

        {config.component === 'Paragraph' && (
          <Paragraph {...config} />
        )}

        {config.component === 'ShareLink' && (
          <ShareLink {...config} />
        )}
      </React.Fragment>
    }
    </React.Fragment>
  );
};

export default ContentElement;
