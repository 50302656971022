import React, {useContext} from "react";
import {Box} from "@mui/material";
import {documentStore} from "documentStore";

export const ParagraphProps = [
  {key: 'textValue', type: 'string', label: 'Paragraph Text', default: 'Enter text...'},
  {key: 'conditional', type: 'conditional', default: {when: 'always', question: null, contains:''}, label: 'Conditional Show'}
]

const Paragraph = ({textValue}) => {
  const {docState} = useContext(documentStore);

  return (
    <Box sx={{
      color: docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main,
      fontSize: '16px',
      marginBottom: '30px',
      overflow: 'auto',
      '& p' : {
        margin: '0'
      }
    }}>
      <p>{textValue}</p>
    </Box>
  )
}

Paragraph.defaultProps = {
  ...ParagraphProps.reduce(
    (acc, cur) => ({...acc, [cur.key]: cur.default})
    , {})
};

export default Paragraph;
