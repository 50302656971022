import React, {useContext} from 'react';
import {Box} from '@mui/material';
import propTypes from "prop-types";
import {documentStore} from "../documentStore";
import RHLogo from "../Atoms/RHLogo";

const PageHeaderLogos = ({url, logoWidth}) => {
  const {appState, docState} = useContext(documentStore);
  const smallLogoWidth = `${logoWidth * 0.70}px`;
  return (
    <Box sx={{padding:'18px 0'}}>
      {!docState.present.kiteTheme.noHeaderLogos && <Box sx={{display:'flex'}}>
        <Box
          component="img"
          src={url}
          sx={{
            height: 'auto',
            width: {sm: smallLogoWidth, md: smallLogoWidth, lg: `${logoWidth}px`, xs: smallLogoWidth}
          }}
          alt="Logo Image"
        />
        <div style={{flex: '1 0 auto'}}/>
        <Box sx={{display: {xs:'none', md: 'block'}}}>
          <RHLogo fillColor={appState.currentPalette.palette ? appState.currentPalette.palette.primary.contrastText : 'pink'}/>
        </Box>
      </Box>}
    </Box>
  );
}

PageHeaderLogos.propTypes = {
  headingState: propTypes.array,
};

export default PageHeaderLogos;
