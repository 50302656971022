import React from 'react';
import Fade from '@mui/material/Fade';

export function Submission() {
    return (
        <div>
            <Fade in={true} timeout={1000} style={{ transitionDelay: '300ms' }}>
                <h1>Thank You</h1>
            </Fade>
            <Fade in={true} timeout={1000} style={{ transitionDelay: '600ms' }}>
                <p>Your answers are on their way</p>
            </Fade>
        </div>
    );
}