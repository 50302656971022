import React from "react";

export const TextFieldProps = [
  {key: 'propertyName', type: 'string', default: 'Property Name', label: "Property Name", disabled: true},
  {key: 'label', type: 'string', default: 'Input Label', label: 'Question'},
  {key: 'conditional', type: 'conditional', default: {when: 'always', question: null, contains:''}, label: 'Conditional Show'}
]

const TextField = () => <div />

export default TextField;
