import React from 'react';
import propTypes from "prop-types";
import {ButtonGroup} from "@mui/material";

const EditorMenu = ({ children, fixed = false }) => {

  return (
    <div
      style={{
        padding:'0 24px 0 24px',
        marginBottom: 32,
        display: 'flex',
        justifyContent: 'flex-end',
        position: fixed ? 'sticky' : 'relative',
        top: fixed ? 160 : 'auto',
      }}
    >
      <ButtonGroup variant="outlined" aria-label="outlined primary button group">
          {children}
      </ButtonGroup>
    </div>
  );
}

EditorMenu.propTypes = {
  children: propTypes.node
};

export default EditorMenu;
