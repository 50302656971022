import React from "react";
import {Box} from '@mui/material';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import propTypes from 'prop-types';

const DocumentElementComplex = (props) => {

  return (
    <Box
      className={`${(props.isSelected) ? 'selected' : ''}`}

      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();
        props.onSelect(props.id);
      }}

      sx={{
        ...props.styleOverride,
        position:'relative',

        '& .add': {
          display: 'none',
          cursor: 'pointer',
          textAlign:'center',
          padding:'6px 0',
          position: 'absolute',
          right: '-36px',
          top: '-4px',
        },

        '& .delete': {
          display: 'none',
          cursor: 'pointer',
          textAlign:'center',
          padding:'6px 0',
          position: 'absolute',
          right: '-36px',
          top: '28px',
        },

        '& .handle': {
          display: 'none',
          textAlign:'center',
          padding:'6px 0',
          position: 'absolute',
          left: '-36px',
          top: '20%',
        },

        '&.selected .add, &.selected .handle, &.selected .delete': {
          display: 'block',
        },

        '&:hover, &.selected': {
          cursor:'pointer',
          boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
        }
      }}
      >
      {props.children}

      {props.dragProvided && props.dragProvided.dragHandleProps && !props.locked
        &&
        <React.Fragment>
          <div className={'add'}
               onClick={() => {
                 props.addElement(props.index+1);
               }}
          ><AddCircleIcon /></div>
          <div className={'delete'}
               onClick={() => {
                 props.deleteElement(props.index);
               }}
          ><DeleteIcon /></div>
          <div className={'handle'}{...props.dragProvided.dragHandleProps}><DragIndicatorIcon /></div>
        </React.Fragment>
      }

    </Box>
  );
};

const DocumentElement = (props) => {
  return (
    <React.Fragment>
      {props.editable && <DocumentElementComplex {...props} />}
      {!props.editable && !props.hidden && <Box
        sx={{
            ...props.styleOverride
      }}
      >
        {props.children}
      </Box>}
    </React.Fragment>
  )

}

DocumentElement.propTypes = {
  isSelected: propTypes.bool,
  id: propTypes.string,
  index: propTypes.number,
  locked: propTypes.bool,
  hidden: propTypes.bool,
  onSelect: propTypes.func,
  addElement: propTypes.func,
  deleteElement: propTypes.func,
  styleOverride: propTypes.object,
  dragProvided: propTypes.object
};

DocumentElement.defaultProps = {
  isSelected: false,
  id: 'none',
  index: 0,
  locked: false,
  hidden: false,
  onSelect: () => {},
  addElement: () => {},
  deleteElement: () => {},
  styleOverride: {},
  dragProvided: {}
};

export default DocumentElement;
