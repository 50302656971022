import React, {useContext, useRef, useState} from "react";
import './SideNav.css';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import ShareIcon from '@mui/icons-material/Share';
import {documentStore} from "../../documentStore";
import {Box} from "@mui/material";
import useClickOutside from "../../hooks/useClickOutside";
import Stack from "@mui/material/Stack";
import {Draggable, Droppable} from "react-beautiful-dnd";
import TextField from "@mui/material/TextField";

const DraggableDropDown = ({id, selected = false, children}) => {
  return <Droppable droppableId={id}>
    {(provided, snapshot) => (
      <Stack
        {...provided.droppableProps}
        ref={provided.innerRef}
        sx={{
          position: 'absolute',
          visibility: selected ? 'visible' : 'hidden',
          left: 80,
          width: 300,
          maxHeight: 500,
          overflowY: 'scroll',
          top: 0,
          zIndex: 100,
          border: '1px solid #dce0e6',
          borderRadius: '1px',
          background: '#fff',
          boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
        }}>
        {children}
        {provided.placeholder}
      </Stack>
    )}

  </Droppable>
}
const MenuOption = ({children, selected = false, onClick = () => {}}) =>
  <Box sx={{
    height: '55px',
    color: '#616063',
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 25px',
    borderLeft: '3px solid #fff',
    cursor: 'pointer',
    transition: 'all ease-in 0.15s',
    '&.selected' : {
      color: 'primary.main',
      borderLeft: `3px solid black`,
      borderColor: 'primary.main',
      backgroundColor: '#ebf1ff'
    },
    '&:hover' : {
      color: 'primary.main',
    }
  }}
       onClick={onClick} className={`${selected ? 'selected' : ''}`}>
    {children}
  </Box>


export default function SideNav() {
  const {appState, docState} = useContext(documentStore);
  const ref = useRef();
  const [selected, setSelected] = useState('');
  const [filter, setFilter] = useState('');
  useClickOutside(ref, () => {setSelected('')});
  useState(true);

  let select = (selection) => {
    setSelected(selection);
  };

  const applyFilter = (question) => {
    const lowerCaseFilter = filter.toLowerCase();
    return question.label.toLowerCase().indexOf(lowerCaseFilter) !== -1
      || question.propertyName.toLowerCase().indexOf(lowerCaseFilter) !== -1
      || question.category.toLowerCase().indexOf(lowerCaseFilter) !== -1
      || filter === '';
  };

  return (
    <div ref={ref}>
      <div className={'side-nav'}>
        <div className={'menu-container'}>
          <nav>
            <ul className={'menu'}>
              <li>
                <MenuOption
                  selected={selected === 'form'}
                  onClick={() => {select('form')}}><QuestionAnswerIcon title={'Form'}/></MenuOption>
                  <DraggableDropDown id={'add_questions'} selected={selected === 'form'} >
                    <React.Fragment>
                      <TextField
                        size={'small'}
                        placeholder={'Filter Questions'}
                        onChange={(e) => setFilter(e.target.value)}
                        sx={{margin: '0 0 10px'}}
                      />
                        {appState.questions.map((question, index) => (
                        <React.Fragment key={question.id}>
                        {!(docState.present.shape.some(shapeComp => shapeComp.id === question.id)) &&
                          applyFilter(question) &&
                          <Draggable key={question.id} draggableId={question.id} index={index}>
                            {(provided, snapshot) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                sx={{
                                  '&:last-child > div' : {
                                    borderBottom: 'none'
                                  }
                                }}
                              >
                                <Box
                                  {...provided.dragHandleProps}
                                  sx={{
                                    background: '#fff',
                                    borderBottom: '1px solid #ccc',
                                    width: '100%',
                                    padding: '8px 16px',
                                    position: 'relative'
                                  }}
                                >
                                  <div style={{
                                    fontSize: 12,
                                    marginBottom: 6,
                                    textTransform: 'uppercase',
                                    color: '#616161'
                                  }}><b>{question.propertyName}</b> - {question.category}</div>

                                  {question.label && <div style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    fontSize: 11,
                                  }}>{question.label}</div>}
                                </Box>
                              </Box>
                            )}
                          </Draggable>}
                        </React.Fragment>
                        )
                      )}
                    </React.Fragment>
                  </DraggableDropDown>
              </li>
              <li>
                <MenuOption
                  selected={selected === 'new_question'}
                  onClick={() => {select('new_question')}}> <MapsUgcIcon title={'New Question'}/></MenuOption>
                <DraggableDropDown id={'add_new_questions'} selected={selected === 'new_question'} >
                  {appState.newQuestionOptions.map((component, index) => (
                      <Draggable key={component.id} draggableId={component.id} index={index}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            sx={{
                              '&:last-child > div' : {
                                borderBottom: 'none'
                              }
                            }}
                          >
                            <Box
                              {...provided.dragHandleProps}
                              sx={{
                                background: '#fff',
                                borderBottom: '1px solid #ccc',
                                width: '100%',
                                padding: '8px 16px',
                                position: 'relative'
                              }}
                            >
                              <div style={{marginBottom: 6}}>{component.type}</div>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    )
                  )}
                </DraggableDropDown>
              </li>
              <li>
                <MenuOption
                  selected={selected === 'text'}
                  onClick={() => {select('text')}}> <FormatSizeIcon title={'Text'}/></MenuOption>
                <DraggableDropDown id={'add_text'} selected={selected === 'text'} >
                  {appState.textOptions.map((component, index) => (
                      <Draggable key={component.id} draggableId={component.id} index={index}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            sx={{
                              '&:last-child > div' : {
                                borderBottom: 'none'
                              }
                            }}
                          >
                            <Box
                              {...provided.dragHandleProps}
                              sx={{
                                background: '#fff',
                                borderBottom: '1px solid #ccc',
                                width: '100%',
                                padding: '8px 16px',
                                position: 'relative'
                              }}
                            >
                              <div style={{marginBottom: 6}}>{component.contentType}</div>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    )
                  )}
                </DraggableDropDown>
              </li>
              <li>
                <MenuOption
                  selected={selected === 'share'}
                  onClick={() => {select('share')}}> <ShareIcon title={'share'}/></MenuOption>
                <DraggableDropDown id={'add_share'} selected={selected === 'share'} >
                  {appState.shareOptions.map((component, index) => (
                      <Draggable key={component.id} draggableId={component.id} index={index}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            sx={{
                              '&:last-child > div' : {
                                borderBottom: 'none'
                              }
                            }}
                          >
                            <Box
                              {...provided.dragHandleProps}
                              sx={{
                                background: '#fff',
                                borderBottom: '1px solid #ccc',
                                width: '100%',
                                padding: '8px 16px',
                                position: 'relative'
                              }}
                            >
                              <div style={{marginBottom: 6}}>{component.contentType}</div>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    )
                  )}
                </DraggableDropDown>
              </li>
              <li>
                <MenuOption
                  selected={selected === 'image'}
                  onClick={() => {select('image')}}> <CropOriginalIcon title={'Image'}/></MenuOption>
                <DraggableDropDown id={'add_image'} selected={selected === 'image'} >
                  {appState.imageOptions.map((component, index) => (
                      <Draggable key={component.id} draggableId={component.id} index={index}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            sx={{
                              '&:last-child > div' : {
                                borderBottom: 'none'
                              }
                            }}
                          >
                            <Box
                              {...provided.dragHandleProps}
                              sx={{
                                borderBottom: '1px solid #ccc',
                                width: '100%',
                                padding: 1,
                                position: 'relative',
                                textAlign: 'center'
                              }}
                            >
                              <img src={component.url} alt={'spacer'} width={40} height={40} />
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    )
                  )}
                </DraggableDropDown>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}
