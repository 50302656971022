import React, {useEffect, useRef, useState} from "react";
import './EditableText.css';

/**
 * Allow arbitrary text to become editable on click.
 * @param value
 * @param format
 * @param onChange
 * @returns {JSX.Element}
 * @constructor
 */
export const EditableText = ({value, format = (val) => val, onChange}) => {
  const node = useRef();
  const [edit, setEdit] = useState(false);
  const [locVal, setLocVal] = useState(value || 0);

  useEffect(() => {
    setLocVal(value);
  }, [value]);

  const handleInputBlur = () => {
    setEdit(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      let newVal = e.target.value;
      setLocVal(e.target.value);
      setEdit(false);
      onChange(newVal);
    }
  }

  return <div ref={node} >
    {!edit && <div className={'et-text-style'} onClick={() => setEdit(true)}>
      {!!locVal && <span>{format(locVal)}</span>}
      {!locVal && <span>Click to edit</span>}
    </div>}
    {edit && <input type={'text'} style={{ width:'100px'}}
                    value={locVal}
                    onKeyDown={handleKeyDown}
                    onBlur={handleInputBlur}
                    autoFocus
                    onFocus={e => e.currentTarget.select()}
                    onChange={(e) => {
                      setLocVal(e.target.value);
                      onChange(e.target.value);
                    }}
    />}</div>;
}
