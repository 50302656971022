const categories = [
  {title: 'Misc'},
  {title: 'Personal'},
  {title: 'Room'},
  {title: 'Food'},
  {title: 'Beverage'},
  {title: 'Activities'},
  {title: 'Music'},
  {title: 'Logistics'},
  {title: 'Marketing'},
  {title: 'Feedback'},
  {title: 'Quiz'}
];

const screenSizes = [
  {title: 'Large'},
  {title: 'Medium'},
  {title: 'Small'}
];

const screenMapping = {
  'Large': 'lg',
  'Medium': 'md',
  'Small': 'sm'
}

const tags = categories.reduce((acc, cur) => {
  return ({...acc, [`${cur.title.toLowerCase()}Tags`]: []});
}, {});

export const CATEGORIES = categories;
export const SCREEN_SIZES = screenSizes;
export const SCREEN_SIZES_MAP = screenMapping;
export const CATEGORY_TAGS = tags;
