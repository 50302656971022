import React, {useContext} from "react";
import Button from '@mui/material/Button';
import {Box} from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import Snackbar from '@mui/material/Snackbar';
import {documentStore} from "documentStore";

export const ShareLinkProps = [
  {key: 'heading', type: 'string', default: 'Share event with your group', Label: 'Share event with your group'},
  {key: 'conditional', type: 'conditional', default: {when: 'always', question: null, contains:''}, label: 'Conditional Show'}
]

const ShareLink = ({heading}) => {
  const {docState} = useContext(documentStore);
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <>
    <Box sx={{
      fontSize: '18px',
      marginTop: '0px',
      marginBottom: '36px',
      '& h3' : {
        fontSize: '16px',
        fontWeight: 'normal',
        margin: '0 0 12px 0'
      },
      '& p' : {
        margin: '0 0 12px 0'
      }
    }}>
      <h3>{heading}</h3>
      <p>
      <Button 
        variant="outlined" 
        startIcon={<LinkIcon />} 
        sx={{color: docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main}}
        onClick={() => {
          navigator.clipboard.writeText(`${window.location.href}?gr=${docState.present.group}`);
          handleClick();
        }}
      >
        Copy Link
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message="Link Copied to Clipboard"
      />
      </p>
    </Box>
    </>
  )
}

ShareLink.defaultProps = {
  ...ShareLinkProps.reduce(
    (acc, cur) => ({...acc, [cur.key]: cur.default})
    , {})
};

export default ShareLink;
