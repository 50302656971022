import React from 'react';

const PageCenter = ({children}) => {
  return (
    <div
      style={{
        maxWidth: '1200px',
        margin: '0 auto',
        width: '100%',
        padding: '0 16px',
        boxSizing: 'border-box',
        zIndex: 1,
        position:'relative'
      }}
    >
      {children}
    </div>
  );
};

export default PageCenter;
