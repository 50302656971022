import React, {useContext} from "react";
import {Box} from "@mui/material";
import {documentStore} from "documentStore";

export const SectionHeadProps = [
  {key: 'paragraph1', type: 'string', default: 'Large Heading (Optional)', Label: 'Large Heading (Optional)'},
  {key: 'paragraph2', type: 'string', default: 'Small Heading (Optional)', label: 'Small Heading (Optional)'},
  {key: 'conditional', type: 'conditional', default: {when: 'always', question: null, contains:''}, label: 'Conditional Show'}
]

const SectionHead = ({paragraph1, paragraph2}) => {
  const {docState} = useContext(documentStore);

  return (
    <Box sx={{
      color: docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main,
      fontSize: '16px',
      marginTop: '30px',
      '& h3' : {
        fontSize: '26px',
        fontWeight: 'normal',
        margin: '0 0 12px 0'
      },
      '& p' : {
        margin: '0 0 12px 0'
      }
    }}>
      <h3>{paragraph1}</h3>
      {paragraph2 && <p>{paragraph2}</p>}
    </Box>
  )
}

SectionHead.defaultProps = {
  ...SectionHeadProps.reduce(
    (acc, cur) => ({...acc, [cur.key]: cur.default})
    , {})
};

export default SectionHead;
