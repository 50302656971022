import {alpha, InputBase, styled} from "@mui/material";

/**
 * Add a form input that's more in keeping with rose castle style.
 * @type {StyledComponent<PropsOf<(props: InputBaseProps) => JSX.Element> & MUIStyledCommonProps<Theme>, {}, {}>}
 */
const BootstrapTextInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0,
  },
  '&.Mui-error .MuiInputBase-input': {
    borderColor: theme.palette.error.main,
    borderWidth: 2,
  },
  '& .MuiInputBase-input': {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
}));

export default BootstrapTextInput;
