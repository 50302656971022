import React from "react";
import {Box, Stack} from "@mui/material";
import NexusLogo from "../../Atoms/NexusLogo";
import UpperMenu from "./UpperMenu";
import propTypes from "prop-types";

const Header = ({id, debouncedState, update, kiteEntry}) => {
    return (
        <Box
            sx={{
                color: '#333',
                top: 0,
                left: 0,
                height: '80px',
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}
        >
            <Stack direction={'row'}>
                <Box sx={{pl: 3, height: 42}}><NexusLogo/></Box>
                <Stack gap={1} sx={{fontSize: 12, paddingLeft: 1}}>
                    {kiteEntry.site && <div>
                        <div style={{fontSize: 15, paddingBottom: 4}}>Campaign: <b
                            style={{textTransform: 'capitalize'}}>{kiteEntry.name}</b></div>
                        <div style={{textTransform: 'uppercase'}}>{kiteEntry.site[0].name}</div>
                    </div>}
                </Stack>
            </Stack>
            <UpperMenu id={id} debouncedState={debouncedState} update={update} />
        </Box>
    )
}

Header.propTypes = {
  id: propTypes.string,
  debouncedState: propTypes.object,
  update: propTypes.func,
  kiteEntry:  propTypes.object
};

export default Header;
