import React from 'react';
import Header from "./Header";
import {Box} from "@mui/material";
import EditorNav from "./EditorNav";
import propTypes from "prop-types";
import {useParams} from "react-router-dom";
import {update} from "../../KiteDocuments/api";


const Layout = ({ kiteEntry, debouncedState, children }) => {
  let { id } = useParams();

  return <>

    <Box sx={{
        position: 'fixed',
        width: '100%',
        height: '128px',
        zIndex: 20,
        top: 0
    }}
         flexDirection={"column"}
    >
        <Header
            id={id}
            debouncedState={debouncedState}
            update={(id, obj) => {
              console.log(obj);
              update(id, obj);
            }}
            kiteEntry={kiteEntry}
        />
        <EditorNav />
    </Box>

    <Box
        sx={{
            height:'100vh',
            pt: '128px'
        }}
    >
        {children}
    </Box>
</>}

Layout.propTypes = {
  title: propTypes.string
}

export default Layout;
