import React, {useRef, useState} from 'react';
import './MultiSelectPossible.css';
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {FormControlLabel, InputAdornment, Switch} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from '@mui/material/TextField';
import {MaterialStyleField} from "../MaterialStyleField";
import {EditableText} from "../EditableText/EditableText";

/**
 * Allows you to view and update site array settings.
 * @constructor
 */
export const MultiSelectPossible = ({item, onChange, property = 'areas', label=""}) => {
  const [localState, setLocalState] = useState({dropTarget: {title:'', index:0}});
  const [beingDragged, setBeingDragged] = useState('');
  const classes = {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'left',
      listStyle: 'none',
      padding: '3px',
      margin: '0 0 16px 0',
    },
    chipControls:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap:6
    },
    chipLabel:{
      marginBottom:8
    },
    add: {
      margin: '0 0 0',
    }
  };

  let textInput = useRef(null);
  let chips = item[property];

  const handleChipsUpdate = (chipValues) => {
      onChange(chipValues);
      textInput.current.value = "";
  }

  const handleDelete = (chipToDelete) => () => {
    const newChips = chips.filter((chip) => chip.title !== chipToDelete.title);
    handleChipsUpdate(newChips);
  };

  const handleEditText = (activeChip, newValue, index) => {
    const newChips = chips.map((chip, localIndex) => localIndex === index ? {...chip, title: newValue} : chip);
    handleChipsUpdate(newChips);
  };

  const handleActive = (activeChip, active) => {
    const newChips = chips.map((chip) => chip.title === activeChip.title ? {...chip, active: active} : chip);
    handleChipsUpdate(newChips);
  };

  const handleNew = (value) => {
    if (value) {
      let values = [{title: value.trim(), active: true}];
      handleChipsUpdate([...chips, ...values]);
    }
  }

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      let value = e.target.value;
      handleNew(value);
    }
  };

  const handleDrop = (e) => {
    const newChips = chips.filter((chip) => chip.title !== beingDragged.title);
    newChips.splice(localState.dropTarget.index, 0, beingDragged);
    handleChipsUpdate(newChips);
    setLocalState({dropTarget: {title: '', index: 0}});
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    return false;
  }

  function handleDragEnter(e, targetName, index) {
    setLocalState({dropTarget: {title: targetName, index: index}});
  }

  return (
    <div style={{padding:16, borderRadius:3}}>
      <MaterialStyleField label={label} />
      {!!item[property].length && <ul style={classes.root}>
        {item[property].map((data, index) => {
          return (
            <li key={index}>
              <div className={`chip ${localState.dropTarget.title === data.title ? 'active' : ''}`}
                   draggable={true}
                   onDragOver={handleDragOver}
                   onDragEnter={(e)=>{ handleDragEnter(e, data.title, index); }}
                   onDrop={(e) => handleDrop(e)}
                   onDrag={(event) => {setBeingDragged(data)}}
              >
                <div style={classes.chipLabel}><EditableText value={data.title} onChange={(newValue)=>{
                  handleEditText(data, newValue, index);
                }} /></div>
                <div style={classes.chipControls}>
                  <FormControlLabel
                    style={{margin:0}}
                    control={
                      <Switch
                        checked={data.active || false}
                        onChange={(e) => {
                          handleActive(data, e.target.checked);
                        }}
                        color="primary"
                        size="small"
                      />
                    }
                    label={'Active'}
                  />
                  <DeleteIcon
                    size="small"
                    onClick={handleDelete(data)}
                  />
                </div>
              </div>
            </li>
          );
        })}
      </ul>}
      <div style={classes.add}>
        <FormControl>
          <TextField
            variant="outlined"
            size={'small'}
            label={'Add New'}
            inputRef={textInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" style={{cursor: 'pointer'}}>
                  <AddCircleOutlineIcon onClick={(e) => {
                    handleNew(textInput.current.value);
                  }}/>
                </InputAdornment>
              ),
            }}
            onKeyDown={keyPress}
          />
        </FormControl>
      </div>
    </div>
  )
}
