import {postPageVisit} from "./api";

/**
 * Will look in localStorage for information indicating the user has visited, if they have not
 * we create a page visit and store it via the api.
 */
export const checkVisited = (eventName, eventId, firstName, lastName) => {
  return new Promise(((resolve, reject) => {
    const pageVisited = getPageVisitInfo();
    if (pageVisited?.eventId === eventId) {
      resolve(pageVisited);
    } else {
      postPageVisit({
        eventName: eventName,
        eventId: eventId,
        firstName: firstName || "Unknown",
        lastName: lastName || "Unknown"
      }).then(data => {
        localStorage.setItem("pageVisited", JSON.stringify(data.data));
        resolve(data.data);
      });
    }
  }));
}

export const getPageVisitInfo = () => {
  const pageVisitedString = localStorage.getItem("pageVisited");
  return JSON.parse(pageVisitedString);
}
