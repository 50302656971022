import Button from "@mui/material/Button";
import PublishIcon from '@mui/icons-material/Publish';
import {Box} from "@mui/material";
import React, {useContext} from "react";
import propTypes from "prop-types";
import {documentStore} from "../../documentStore";
import {format} from "date-fns";

const UpperMenu = ({id, debouncedState, update}) => {
  const {appState, appDispatch} = useContext(documentStore);
  return <Box sx={{
    pr: 3,
    display: 'flex',
    gap: 3
  }}>
    <div style={{fontSize:12, textAlign:'right'}}>
      <div style={{marginBottom:5}}>Last Published: {appState.lastPublished ?
        format(new Date(appState.lastPublished), 'do MMM, HH:mm') : '-'}</div>
      <a href={`/kitecampaign/${id}`} target={`${id}_live`} style={{fontSize:13, textDecoration:'none', color:'#1976d2'}}>View Live Kite</a>
    </div>
    <Button
      variant="contained"
      onClick={() => {
        const newLastPublished = new Date();
        update(id, {liveDocument: {...debouncedState}, lastPublished: newLastPublished });
        appDispatch({ type: 'PUBLISH', payload: newLastPublished });
      }}
      endIcon={<PublishIcon/>}
    >Publish</Button>
  </Box>
}

UpperMenu.propTypes = {
  id: propTypes.string,
  debouncedState: propTypes.object,
  update: propTypes.func
}

export default UpperMenu
