import React, {useContext} from 'react';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {documentStore} from "../documentStore";
import propTypes from "prop-types";

const ContactUs = ({email, heading1, heading2, contactButton, hide}) => {
  const {docState} = useContext(documentStore);
  return (
    <React.Fragment>
    {!hide && <div style={{
      textAlign: 'center',
      maxWidth: 400,
      margin: '0 auto 36px'
    }}>
    <Typography variant="div" component="div" style={
      {
        textAlign: 'center',
        fontSize: '20px',
        color:  docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main
      }
    }>
      {heading1}
    </Typography>
    <Typography variant="div" component="div" style={{
      textAlign: 'center',
      fontSize: '50px',
      margin: '20px 0 26px',
      color:  docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main
    }}>
      {heading2}
    </Typography>
    <Button
      style={{borderRadius:0, padding:'16px 35px', fontSize:18, boxShadow: 'none'}}
      size={'large'}
      variant="contained"
      color="secondary"
      href={`mailto:${email}`} target="_blank" rel="noopener"
    >{contactButton}</Button>
    </div>}</React.Fragment>)
}

export const ContactUsProps = [
  {key: 'email', type: 'string', default: 'steward@rosecastle.com'},
  {key: 'heading1', type: 'string', default: 'Need some extra help?'},
  {key: 'heading2', type: 'string', default: 'Contact your Steward right now'},
  {key: 'contactButton', type: 'string', default: 'Get In Touch'},
  {key: 'hide', type: 'bool', default: false, label: 'Hide'}
]

ContactUs.defaultProps = ContactUsProps.reduce(
  (acc, cur) => ({...acc, [cur.key]: cur.default})
  , {});

ContactUs.propTypes = {
  email: propTypes.string,
  heading1: propTypes.string,
  heading2: propTypes.string,
  contactButton: propTypes.string,
  hide: propTypes.bool,
};

export default ContactUs;
