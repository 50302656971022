import React, {useState} from "react";
import Button from "@mui/material/Button";
import EditorMenu from "../Molecules/EditorMenu";

const PreviewEdit = ({id}) => {
  const [size, setSize] = useState(1200);

  return <React.Fragment>
    <EditorMenu>
      <Button onClick={()=>{setSize(500)}} color={`${size === 500 ? 'secondary' : 'primary'}`}>Small</Button>
      <Button onClick={()=>{setSize(800)}} color={`${size === 800 ? 'secondary' : 'primary'}`}>Medium</Button>
      <Button onClick={()=>{setSize(1200)}} color={`${size === 1200 ? 'secondary' : 'primary'}`}>Large</Button>
    </EditorMenu>

    <div style={{margin: '0 auto', width: size, height: 723}}>
      <iframe src={`/preview/${id}`} title={'View Preview'} style={{
        border: 'none',
        width: '100%',
        height: '100%'
      }}/>
    </div>
  </React.Fragment>
}

export default PreviewEdit;
