import React from "react";
import {Button, FormControlLabel, Switch, TextField} from "@mui/material";
import {ContactUsProps} from "../Molecules/ContactUs";
import {VenueContactDetailsProps} from "../Molecules/VenueContactDetails";
import {postFile} from "../Services/api";
import {FormSubmittedProps} from "../Molecules/FormSubmitted";
import {TextFieldProps} from "../Atoms/FormElements/TextField";
import {MultiSelectNewProps, MultiSelectProps} from "../Atoms/FormElements/MultiSelect";
import FormControl from "@mui/material/FormControl";
import {MultiSelectPossible} from "../Atoms/MultiSelectPossible/MultiSelectPossible";
import {IntroParagraphProps} from "../Atoms/FormContent/IntroParagraph";
import {SectionHeadProps} from "../Atoms/FormContent/SectionHead";
import {ShareLinkProps} from "../Atoms/FormContent/ShareLink";
import {PageHeaderProps} from "../Molecules/PageHeader";
import {ParagraphProps} from "../Atoms/FormContent/Paragraph";
import {TextFieldNewProps} from "../Atoms/FormElements/TextFieldNew";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {ConditionalDisplay} from "../Atoms/ConditionalDisplay/ConditionalDisplay";

const ComponentProps = {
  'ContactUs': ContactUsProps,
  'VenueContactDetails': VenueContactDetailsProps,
  'FormSubmitted': FormSubmittedProps,
  'TextField': TextFieldProps,
  'TextFieldNew': TextFieldNewProps,
  'MultiSelect' : MultiSelectProps,
  'MultiSelectNew' : MultiSelectNewProps,
  'IntroParagraph': IntroParagraphProps,
  'SectionHead': SectionHeadProps,
  'PageHeader': PageHeaderProps,
  'Paragraph': ParagraphProps,
  'ShareLink': ShareLinkProps,
  'ShareLinkMultiSelect': MultiSelectProps
}

const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

const ComponentEditor = ({config, onChange}) => {
  let componentName = config.component || capitalize(config.type);
  if (config.local) {
    componentName += 'New';
  }

  if (!ComponentProps[componentName]) {
    return (<div></div>);
  }
  return (
    <React.Fragment>
    {ComponentProps[componentName].map((element, index) => (
      <React.Fragment key={index}>
      {!element.hidden && <React.Fragment>
        {element.type === 'number' && <TextField
          label={element.label || capitalize(element.key)}
          type={'number'}
          value={((config[element.key] || config[element.key] === '') ? config[element.key] : element.default)}
          onChange={(e) => {
            let value = e.target.value;
            onChange(value, element.key);
          }}
          onBlur={()=>{}}
          variant="outlined"
        />}

      {element.type === 'string' && !element.image && <TextField
          label={element.label || capitalize(element.key)}
          value={((config[element.key] || config[element.key] === '') ? config[element.key] : element.default)}
          onChange={(e) => {
            let value = e.target.value;
            onChange(value, element.key);
          }}
          multiline
          onBlur={()=>{}}
          disabled={element.disabled}
          variant="outlined"
        />}

        {element.type === 'possibleMultiSelect' && (
          <FormControl variant="outlined" className="control">
            <MultiSelectPossible item={config} onChange={(values)=>{
              onChange(values, element.key);
            }} property={`${element.key}`} label={element.label} />
          </FormControl>
        )}

        {element.type === 'select' && (
          <FormControl variant="outlined">
            <InputLabel id="position-label">{element.label}</InputLabel>
            <Select
              labelId={`${element.id}-label`}
              label={element.label}
              id={element.id}
              value={config[element.key]}
              onChange={e => {
                const value = e.target.value;
                onChange(value, element.key);
              }}
            >
              {element.selectValues.map(value => (
                <MenuItem value={value.value || value.title} key={value.title}>
                  {value.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {element.type === 'conditional' && <ConditionalDisplay
          label={element.label}
          property={`${element.key}`}
          config={config}
          onChange={(value)=>{onChange(value, element.key);}}
          currentValue={config[element.key]}  />
        }

        {element.type === 'bool' && <FormControlLabel
          control={<Switch
            onChange={(e) => {
              let value = e.target.checked;
              onChange(value, element.key);
            }}
            checked={(config[element.key] || element.default)} />}
            label={element.label}
          />}

        {element.type === 'string' && element.image &&
          <React.Fragment>
            <TextField
              label={`Enter url of ${element.key}`}
              type={'text'}
              value={(config[element.key] || config[element.key] === '' ? config[element.key] : element.default)}
              onChange={(e) => {
                onChange(e.target.value , element.key);
              }}
              onBlur={()=>{}}
              variant="standard"
              size="small"
            />

            <Button
              variant="contained"
              component="label"
            >
              Or Upload New Image
              <input
                type="file"
                hidden
                onChange={(event) => {
                  const data = new FormData();
                  data.append('image', event.target.files[0]);
                  postFile(data).then(returnData => {
                    if (returnData.data.url) {
                      onChange(returnData.data.url , element.key);
                    }
                  });
                }}
              />
            </Button>
          </React.Fragment>
          }
      </React.Fragment>}
      </React.Fragment>
    ))}
    </React.Fragment>
  )
}

export default ComponentEditor;
