import {Box} from "@mui/material";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useParams} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const EditorNav = () => {
  let navigate = useNavigate();
  let { id, tab } = useParams();
  let selectedTab = parseInt(tab) || 0;

  const changeTab = (event, newValue) => {
    navigate(`/edit/${id}/${newValue}`);
  };

  return <Box sx={{
      borderBottom: 1,
      borderColor: 'divider',
      backgroundColor: '#eee',
      width: '100%',
      justifyContent: 'center',
      display: 'flex'
  }}>
      <Tabs value={selectedTab} onChange={changeTab} aria-label="basic tabs example" style={{width: '235px'}}>
          <Tab label="Components" />
          <Tab label="Preview" />
      </Tabs>
  </Box>
}

export default EditorNav;
