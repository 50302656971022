import React from 'react';

const FooterSwoosh = ({background = 'primary.main'}) => {
  return (<div style={{
    position: 'absolute',
    top: '0',
    height: '350px',
    width: '100%',
    left: '0px',
    overflow:'hidden'
  }}><svg xmlns="http://www.w3.org/2000/svg"
                                         style={{
                                           position: 'absolute',
                                           left: '50%',
                                           transform: 'translateX(-50%)'
                                         }}
                                         width="1920"
                                         height="405.957"
                                         viewBox="0 0 1920 405.957">
    <path id="footer" d="M-2166.128,566.723s208.323-74.062,414.206-93.9c24.489-2.36,48.925-5.232,73.322-8.406,80.248-10.442,333.443-23.922,859.2,102.307,0,0,522.553,125.107,573.276,130.213V412h-1920Z" transform="translate(2166.128 -412)"  fill={background} />
  </svg></div>)
}

export default FooterSwoosh;
