import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import React, {useContext, useState} from "react";
import {documentStore} from "../documentStore";
import SideNav from "./SideNav/SideNav";
import Stack from "@mui/material/Stack";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import {Box, Drawer} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ComponentEditor from "../formBuilding/ComponentEditor";
import {updateSelectValues} from "../helpers/utils";
import {camelCase, get} from 'lodash';
import EditorMenu from "../Molecules/EditorMenu";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import ShareIcon from '@mui/icons-material/Share';
import { createQuestion } from "KiteDocuments/api";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#eee" : "transparent",
});

const SectionHeadLocal = ({paragraph1, paragraph2}) =>
  <div>
    <h3 style={{fontWeight:'normal'}}>{paragraph1}</h3>
    {paragraph2 && <p style={{margin:0}}>{paragraph2}</p>}
  </div>

const ParagraphLocal = ({textValue}) =>
  <div>
    <p>{textValue}</p>
  </div>

const ShareLinkLocal = ({heading, label, possibleValues}) =>
<div style={{background:'#eee', padding:'5px' }}>
<div><ShareIcon title={'share'} sx={{
    position: 'relative',
    top: '5px',
  }}/> {!!possibleValues && <span>Question</span>} {!possibleValues && <span>Link</span>}</div>
  {possibleValues && <>
    <p>{label}</p>
    {possibleValues && !!possibleValues.length &&
      possibleValues.map((values, index) => (
        <React.Fragment key={index}>
          {values.active && <div style={{
            display: 'inline-block',
            border:'1px solid #ccc',
            padding:2,
            backgroundColor: '#fff',
            margin:'6px 6px 0 0'
          }}>{values.title}</div>}
        </React.Fragment>
      ))}
    </>}
  <p>{heading}</p>
</div>

const IntroLocal = ({paragraph1, paragraph2, paragraph3}) =>
  <div>
    <p>{paragraph1}</p>
    {paragraph2 && <p>{paragraph2}</p>}
    {paragraph3 && <p>{paragraph3}</p>}
  </div>

const ContactUsLocal = ({ heading1, heading2, onClick}) =>
  <FixedSection onClick={onClick} name={'Contact Us'}>
    <p>{heading1}</p>
    <p>{heading2}</p>
  </FixedSection>

const PageHeaderLocal = ({eventTitle, eventSubtitle, onClick}) =>
  <FixedSection onClick={onClick} name={'Header'}>
    <h1 style={{fontSize:18}}>{eventTitle}</h1>
    <h2 style={{fontSize:16}}>{eventSubtitle}</h2>
  </FixedSection>

const FormSubmittedLocal = ({text1, text2, onClick}) =>
  <FixedSection onClick={onClick}  name={'Form Submitted Message'}>
    <p>{text1}</p>
    <p>{text2}</p>
  </FixedSection>

const FooterLocal = ({address, copyright = '© Rose Castle Company Limited 2022', onClick}) =>
  <FixedSection onClick={onClick} style={{borderBottom: '1px dashed #ccc', paddingBottom:16}} name={'Footer'}>
    <p>{address}</p>
    <p>{copyright}</p>
  </FixedSection>

const FixedSection = ({children, onClick,  name, style}) => {
  return <div
    onClick={onClick} style={{
      position: 'relative',
      cursor: 'pointer',
      textAlign: 'center',
      margin: '16px 0',
      overflow: 'auto',
      borderBottom: '1px solid #ccc',
      ...style
    }}
  >
    <div
      style={{
        position:'absolute',
        fontSize: 12,
        left:4,
        top:0
    }}
    >{name}</div>
    {children}
  </div>
}


const EditComponents = ({loadedDoc, kiteEntry, id}) => {
  const {docState, docDispatch, appState} = useContext(documentStore);
  const [componentEditState, setComponentEditState] = useState({
    open: false,
    index: 0
  });

  const handleClose = () => {
    setComponentEditState(prevState => ({
      ...prevState,
      open: false,
      index: 0
    }));
  };

  const handleOpen = (id, index = 0) => {
    setComponentEditState(prevState => ({
      ...prevState,
      open: true,
      id: id,
      index: index
    }));
  }

  // a little function to help us with reordering the result
  // @TODO duplicate
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const generateID = (shape) => {
    let newID = `newComponent${shape.length}`;
    let checkForID = component => component.id === newID;
    while ((shape.filter(checkForID).length)) {
      newID += 'i';
    }
    return newID;
  }

  const onDragEnd = (result, item) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let items = [];

    if (result.source.droppableId === 'droppable') {
      items = reorder(
        [...docState.present.shape],
        result.source.index,
        result.destination.index
      );
    } else if (result.source.droppableId === 'add_questions') {
      items = [...docState.present.shape];
      let questionConfig = appState.questions[result.source.index];
      updateSelectValues(questionConfig);
      items.splice(result.destination.index, 0, questionConfig);
    } else if (result.source.droppableId === 'add_new_questions') {
      items = [...docState.present.shape];
      items.splice(result.destination.index, 0, {
        ...appState.newQuestionOptions[result.source.index],
        id: generateID(docState.present.shape)
      });
    } else if (result.source.droppableId === 'add_text') {
      items = [...docState.present.shape];
      items.splice(result.destination.index, 0, {
        ...appState.textOptions[result.source.index],
        id: generateID(docState.present.shape)
      });
    } else if (result.source.droppableId === 'add_share') {
      items = [...docState.present.shape];
      items.splice(result.destination.index, 0, {
        ...appState.shareOptions[result.source.index]
      });
    } else if (result.source.droppableId === 'add_image') {
      items = [...docState.present.shape];
      items.splice(result.destination.index, 0, {
        ...appState.imageOptions[result.source.index],
        id: generateID(docState.present.shape)
      });
    }

    docDispatch({
      type: 'update shape',
      payload: items
    });
  }

  const deleteItem = (id) => {
    docDispatch({
      type: 'delete shape entry',
      payload: id
    });
  };

  const getStyles = (type, conditionActive) => {
    let contentStyles = {
      border: `1px solid #ccc`,
      background: '#fff',
      padding: '8px'
    };

    if (type === 'image' || type === 'content') {
      contentStyles['background'] = '#eee';
    }

    if (conditionActive) {
      contentStyles['background'] = 'rgb(252, 247, 224)';
    }

    return contentStyles;
  }

  const makeGlobal = (config) => {
    let newConfig = {...config};
    delete newConfig.local;
    delete newConfig.selectValues;
    delete newConfig.textValue;
    delete newConfig.conditional;
    createQuestion(newConfig).then((response) => {
      updateComponentProperty(false, 'local');
    });
  }

  const updateComponentProperty = (value, property) => {
    docDispatch({
      type: `update ${componentEditState.id}`,
      payload: docState.present[componentEditState.id].map((componentConfig, index) => {
        if (index === componentEditState.index) {
          let newComponentConfig = {...componentConfig, [property]: value};
          updateSelectValues(newComponentConfig); // Apply updates to the possible select values.
          if (newComponentConfig.propertyName &&
            (newComponentConfig.propertyName !== 'Text Field' && newComponentConfig.propertyName !== 'Update property name...')
          ) {
            newComponentConfig.id = camelCase(newComponentConfig.propertyName);
          }
          return newComponentConfig;
        } else {
          return componentConfig;
        }
      })
    });
  }

  return <React.Fragment>
      <Drawer
        anchor={'right'}
        open={componentEditState.open}
        onClose={handleClose}
        disableRestoreFocus={true}
      >
      <DialogTitle>{"Edit Component"}</DialogTitle>
      <DialogContent style={{maxWidth:500}}>
        <Stack spacing={5} sx={{minWidth: '450px', paddingTop:'6px'}}>
          {componentEditState.id && <ComponentEditor onChange={updateComponentProperty} config={docState.present[componentEditState.id][componentEditState.index]} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        {componentEditState.id &&
         docState.present[componentEditState.id][componentEditState.index].local &&
         <React.Fragment><Button style={{float:'left'}} onClick={()=>{makeGlobal(docState.present[componentEditState.id][componentEditState.index])}}>Make Global</Button>
        <div style={{flex: '1 0 0'}} /></React.Fragment>}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Drawer>

    <EditorMenu fixed={true}>
      <Button onClick={() => {docDispatch({ type: 'UNDO' });} } disabled={docState.past.length === 0}><UndoIcon/></Button>
      <Button onClick={() => {docDispatch({ type: 'REDO' });} } disabled={docState.future.length === 0}><RedoIcon/></Button>
    </EditorMenu>

    <DragDropContext onDragEnd={onDragEnd}>
      <SideNav />

      <div style={{
        margin: '0 auto',
        width: 760
      }}>

        {<PageHeaderLocal {...docState.present.pageHeader[0]} onClick={() => {handleOpen('pageHeader')}} />}

        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Stack
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                borderBottom: '1px solid #ccc',
                marginBottom: '8px',
                paddingBottom: '8px'
            }}>

              {docState.present.shape.map((component, index) => (
                  <Draggable key={component.id} draggableId={component.id} index={index}>
                    {(provided, snapshot) => (
                      <div style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                           ref={provided.innerRef}
                           {...provided.draggableProps}>
                        <Box
                          onClick={() => {handleOpen('shape', index)}}
                          sx={{
                            ...getStyles(component.type, get(component, 'conditional.when', 'always') !== 'always'),
                            width: '100%',
                            marginBottom: 1,
                            cursor: 'pointer',
                            position: 'relative',
                            '& .handle': {
                              opacity: 0,
                              display:'block',
                              transition: 'opacity ease-in 0.3s',
                              textAlign:'center',
                              padding:'6px 36px 6px 2px',
                              position: 'absolute',
                              left: '-36px',
                              top: '50%',
                              transform: 'translate(0, -50%)'
                            },
                            '& .delete': {
                              display: 'none',
                              textAlign:'center',
                              padding:'6px 0',
                              position: 'absolute',
                              right: '4px',
                              top: 0
                            },
                            '&:hover': {
                              '& .delete, & .handle': {
                                display: 'block',
                                opacity: '1'
                              }
                            }
                          }}
                        >
                          <div className={'handle'} {...provided.dragHandleProps}><DragIndicatorIcon/></div>
                          <div className={'delete'}
                               onClick={(e) => {
                                 e.stopPropagation();
                                 deleteItem(component.id);
                               }}
                          ><DeleteIcon /></div>

                          {component.component && <React.Fragment>
                            {component.component === 'IntroParagraph' && <IntroLocal {...component} />}
                            {component.component === 'SectionHead' && <SectionHeadLocal {...component} />}
                            {component.component === 'Paragraph' && <ParagraphLocal {...component} />}
                            {(component.component === 'ShareLink' || component.component === 'ShareLinkMultiSelect') && <ShareLinkLocal {...component} />}
                          </React.Fragment>}

                          {!component.component &&
                            <React.Fragment>
                              {component.type === 'image' && <div style={{textAlign: 'center', lineHeight:'1px'}}>
                                <img src={component.url} width={30} height={30} alt={'spacer'} />
                              </div>}

                              {component.type !== 'image' &&
                                <React.Fragment>
                                  {component.label && component.label !== 'Your Property' && <div>
                                    {component.textValue && <div style={{fontSize:12}}>{component.textValue}</div>}
                                    <div>{component.label}</div>
                                    {component.possibleValues && !!component.possibleValues.length &&
                                      component.possibleValues.map((values, index) => (
                                        <React.Fragment key={index}>
                                          {values.active && <div style={{
                                            display: 'inline-block',
                                            border:'1px solid #ccc',
                                            padding:2,
                                            margin:'6px 6px 0 0'
                                          }}>{values.title}</div>}
                                        </React.Fragment>
                                      ))}
                                  </div>}
                                </React.Fragment>
                              }
                            </React.Fragment>
                          }
                        </Box>
                      </div>
                    )}
                  </Draggable>
                )
              )}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>

        {<ContactUsLocal {...docState.present.contact[0]} onClick={() => {handleOpen('contact')}} />}
        {<FooterLocal {...docState.present.footer[0]} onClick={() => {handleOpen('footer')}}  />}
        {<FormSubmittedLocal {...docState.present.submitted[0]} onClick={() => {handleOpen('submitted')}}  />}

      </div>

    </DragDropContext>

    {/*!appState.selected.done && <DraggableForm
      editable={true}
      shape={docState.present.shape}
      addNew={()=>{
        //handleClickOpen(index);
      }}
      deleteItem={(index) => {
        //deleteItem(index);
      }}
      selected={appState.selected}
    />*/}
  </React.Fragment>
}

export default EditComponents;
