import React, {useContext} from "react";
import {Box} from "@mui/material";
import {documentStore} from "documentStore";

export const IntroParagraphProps = [
  {key: 'paragraph1', type: 'string', label: 'First Paragraph', default: 'At Rose Castle, whether you’re gathering with friends and colleagues for a banquet, planning to enjoy a lazy evening in or looking to da the night away in the Castle, our team are always ready to wow you with your personal delights.'},
  {key: 'paragraph2', type: 'string', default: 'To make sure we get it right for you, be sure to let us know as much as possible.', label: 'Second Paragraph (Optional)'},
  {key: 'paragraph3', type: 'string', default: '', label: 'Third Paragraph (Optional)'},
]

const IntroParagraph = ({paragraph1, paragraph2, paragraph3}) => {
  const {docState} = useContext(documentStore);
  return (
    <Box sx={{
      color: docState.present.kiteTheme.text.statement || docState.present.kiteTheme.primary.main,
      fontSize: '17px',
      marginBottom: '30px',
      marginTop: '56px',
      overflow: 'auto',
      '& p' : {
        margin: '0 0 28px 0'
      }
    }}>
      <p>{paragraph1}</p>
      {paragraph2 && <p>{paragraph2}</p>}
      {paragraph3 && <p>{paragraph3}</p>}
    </Box>
  )
}

IntroParagraph.defaultProps = {
  ...IntroParagraphProps.reduce(
    (acc, cur) => ({...acc, [cur.key]: cur.default})
    , {})
};

export default IntroParagraph;
