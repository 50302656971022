import { useReducer } from 'react';

const useUndoReducer = (reducer, initialState) => {
  const undoState = {
    past: [],
    present: initialState,
    future: []
  };

  const undoReducer = (state, action) => {
    const { past, present, future } = state;

    if (action.type === useUndoReducer.types.undo) {
      const pastPosition = past.length - 1;
      if (pastPosition < 0) {
        return state;
      }
      const previous = past[pastPosition]
      const newPast = past.slice(0, pastPosition)
      return {
        past: newPast,
        present: previous,
        future: [present, ...future]
      }
    }
    if (action.type === useUndoReducer.types.redo) {
      if (future.length === 0) {
        return state;
      }
      const next = future[0]
      const newFuture = future.slice(1)
      return {
        past: [...past, present],
        present: next,
        future: newFuture
      }
    }
    if (action.type === useUndoReducer.types.reset) {
      return {
        past: [],
        present: {...present, ...action.payload},
        future: []
      }
    }
    const newPresent = reducer(state.present, action);
    if (present === newPresent) {
      console.log('no change');
      return state;
    }
    return {
      past: [...past, present],
      present: newPresent,
      future: []
    };
  };

  return useReducer(undoReducer, undoState);
};

useUndoReducer.types = {
  undo: 'UNDO',
  redo: 'REDO',
  reset: 'RESET'
};

export default useUndoReducer;
