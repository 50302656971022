import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import React, {useContext} from "react";
import {documentStore} from "../../documentStore";
import {TextField} from "@mui/material";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

export const ConditionalDisplay = ({config, onChange, property = 'areas', label="", currentValue}) => {
  const {docState} = useContext(documentStore);

  return (<><FormControl variant="outlined">
    <InputLabel id={`${config.id}-label`}>{label}</InputLabel>
    <Select
      label={label}
      id={config.id}
      value={currentValue?.when || 'always'}
      onChange={e => {
        const value = e.target.value;
        onChange({...currentValue, when:value}, property);
      }}
    >
      {[{title: 'Always', value: 'always'}, {title: 'When', value: 'when'}].map(value => (
        <MenuItem value={value.value || value.title} key={value.title}>
          {value.title}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
    {(currentValue?.when && currentValue.when !== 'always') && <div style={{display: 'flex', flexDirection:'column', padding: '20px 10px', borderRadius:'0 0 4px 4px', background: '#fcf7e0', marginTop:0}}>
      <FormControl>
        <InputLabel id={`${config.id}-label`}>Question</InputLabel>
        <Select
          label={'Question'}
          id={`${config.id}-questions`}
          placeholder={'Select Question'}
          value={currentValue?.question}
          onChange={e => {
            const value = e.target.value;
            onChange({...currentValue, question:value}, property);
          }}
        >
          {docState.present.shape.filter((component)=> (component.type === 'multiSelect' || component.type === 'textField')).map((component, index) => (
            <MenuItem value={component.id} key={index}>
              {component.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div style={{textAlign: 'center', margin:'10px 0'}}>- Contains -</div>
      <TextField
        label={'Matching text'}
        value={currentValue?.contains}
        onChange={(e) => {
          const value = e.target.value;
          onChange({...currentValue, contains:value}, property);
        }}
        onBlur={()=>{}}
        variant="outlined"
      />
    </div>}
</>)
}

const checkForMatch = (optionText, conditionalText) => {
  // check for case insensitive match between optionText and conditionalText, conditionalText can
  // include the OR operator which will be used to match any of the words
  if (!conditionalText) return false;
  if (conditionalText.indexOf(' OR ') !== -1) {
    const words = conditionalText.split(' OR ');
    return words.filter(word => optionText.match(new RegExp(word, 'i'))).length > 0;
  }
  return optionText.match(new RegExp(conditionalText, 'i'));
}

export const ConditionalShow = (props) => {
  const conditional = props.config.conditional || false;
  let show = false;
  let showAlways = !conditional || conditional.when === 'always';
  if (conditional) {
    const foundQuestion = Object.keys(props.questions).find(element => element === conditional.question);
    if (foundQuestion) {
      if (Array.isArray(props.questions[foundQuestion])) {
        show = props.questions[foundQuestion].filter(option => (checkForMatch(option.title, conditional.contains) && option.selected)).length > 0;
      } else {
        show = props.questions[foundQuestion] && checkForMatch(props.questions[foundQuestion], conditional.contains);
      }
    }
  }
  return <>
    {(showAlways) && props.children}
    {!showAlways && <SlideDown className={'my-dropdown-slidedown'}>
      {show ? props.children : null}
    </SlideDown>}
  </>
}
