// store.js
import React, {createContext, useReducer} from 'react';
import useUndoReducer from "./reducers/useUndoReducer";

const initialState = {
  selected :{
    key: '',
    index: 0,
    tab: 0,
    done: false
  },
  newQuestionOptions: [
    {
      category: "Misc",
      id: "textField",
      label: "Update Question...",
      propertyName: "Update property name...",
      required: false,
      type: "textField",
      local: true
    },
    {
      category: "Misc",
      id: "multiSelect",
      label: "Update Question...",
      propertyName: "Update property name...",
      possibleValues: [{title: "One", active: true}, {title: "Two", active: true}],
      selectValues: [{title: "One", selected: false}, {title: "Two", selected: false}],
      required: false,
      type: "multiSelect",
      local: true
    }
  ],
  shareOptions: [
    {
      category: "Logistics",
      id: "share_group_question",
      propertyName: "share_group_question",
      component: "ShareLinkMultiSelect",
      label: "Are you part of a group?",
      possibleValues: [{title: "Yes", active: true}, {title: "No", active: true}],
      selectValues: [{title: "Yes", selected: false}, {title: "No", selected: false}],
      shortLabel:"Share Group",
      type:"multiSelect",
      contentType: "Group Question"
    },
    {
      id: "share_link",
      component: "ShareLink",
      heading: "Share this event with your group",
      type:"share",
      contentType: "Share With Group"
    }
  ],
  textOptions: [
    {
      id: "intro_paragraph",
      component: "IntroParagraph",
      paragraph1: "At Rose Castle, whether you’re gathering with friends and colleagues for a banquet, planning to enjoy a lazy evening in or looking to da the night away in the Castle, our team are always ready to wow you with your personal delights.",
      paragraph2: "To make sure we get it right for you, be sure to let us know as much as possible.",
      paragraph3: "",
      type:"content",
      contentType: "Intro Paragraphs"
    },
    {
      id: "section_head",
      component: "SectionHead",
      paragraph1: "Heading",
      paragraph2: "Subheading",
      type:"content",
      contentType: "Section Head"
    },
    {
      id: "paragraph",
      component: "Paragraph",
      textValue: "Enter your desired text...",
      type:"content",
      contentType: "Paragraph"
    },

  ],
  imageOptions: [{
    id: "dragonfly",
    type:"image",
    url:"https://f.hubspotusercontent00.net/hubfs/19963604/Landing%20Page%202021/Image%2039.png"
  }],
  questions: [{
    id: "favourite_color",
    label: "Whats your favourite color?",
    selectValues: [{title: "Green"}, {title: "Red"}, {title: "Blue"}],
    shortLabel:"Fav Color",
    type:"multiselect"
  }],
  currentPalette: {},
  lastPublished: ''
};

const initialStateDocument = {
  kiteTheme: {
  },
  settings: {
  },
  heading: [
  ],
  pageHeader: [
    {component:'PageHeader'}
  ],
  footer: [
    {component:'VenueContactDetails'}
  ],
  contact: [
    {component:'ContactUs'}
  ],
  submitted: [
    {component:'FormSubmitted'}
  ],
  shape: [
  ]
};

const documentStore = createContext({doc:{}, app:{}});
const { Provider } = documentStore;

const documentReducer = (state = initialStateDocument, action) => {
  const { type, payload } = action;
  switch(type) {
    case 'update kite theme':
      return {...state, kiteTheme: {...state.kiteTheme, ...payload}};
    case 'update heading':
      return {...state, heading: payload};
    case 'update pageHeader':
      return {...state, pageHeader: payload};
    case 'update contact':
      return {...state, contact: payload};
    case 'update submitted':
      return {...state, submitted: payload};
    case 'update footer':
      return {...state, footer: payload};
    case 'update document':
      return {...state, ...payload};
    case 'update shape':
      return {...state, shape: payload};
    case 'delete shape entry':
      return {...state, shape: state.shape.filter( (item) => (item.id !== payload))};
    default:
      return state;
  }
};

const appReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch(type) {
    case 'update selected':
      return {...state, selected: {...state.selected, ...payload}};
    case 'update palette':
      return {...state, currentPalette: payload};
    case 'update questions':
      return {
        ...state,
        questions: payload
      };
    case 'PUBLISH':
      return {
        ...state,
        lastPublished: payload
      };
    default:
      return state;
  }
};

const StateProvider = ( { children } ) => {
  const [docState, docDispatch] = useUndoReducer(documentReducer, initialStateDocument);
  const [appState, appDispatch] = useReducer(appReducer, initialState);

  return <Provider value={{
    docState,
    docDispatch,
    appState,
    appDispatch
  }}>{children}</Provider>;
};

export { documentStore, StateProvider };
